/* Tailwind CSS utilities */
@tailwind base;      /* Add this line */
@tailwind components; /* Add this line */
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

html {
    scroll-behavior: smooth;
  }
/* Hide scrollbar for Chrome, Safari, and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
html, body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  font-family: 'Montserrat', sans-serif;
}


@media (max-width: 550px) {
    .iphone-flex-col {
      @apply flex-col;
    }
    .widmap{
      @apply w-[200%];
    }
    .padcontact{
      @apply mt-[250px];
      
    }
    .widhero{
      @apply w-auto;
    }
    
    /* .mthero{
      @apply mt-[0px];
    } */

  }
